export const CADASTRA = "https://cadastra.timob.com.br";
export const POS = "https://pos.timob.com.br";
export const TRANSACIONA = "https://transaciona.timob.com.br";
export const ARQUIVO = "https://arquivo.timob.com.br";
export const BHTRANS = "https://bhtrans.timob.com.br";
export const BHTRASPFDV = "https://bhtrans-pfdv.timob.com.br";
export const FISCALIZA = "https://fiscaliza.timob.com.br";
export const AUTENTICA = "https://autentica.timob.com.br";
export const MINUTE = 60 * 1000;
export const API_KEYGMAP = "AIzaSyB21qRpnpkEMRhPzOnMv7ro01C4CB4aT-c";

export const auth = [
  {
    username: "admin",
    password: "mX2BXjNQRv",
    city: "",
  },
  {
    username: "belohorizonte",
    password: "xdDS0Vm08c",
    city: "Belo Horizonte",
  },
  {
    username: "belohorizontepdv",
    password: "BHf34wJqLH",
    city: "Belo Horizonte",
  },
  {
    username: "igarape",
    password: "QOHFwogcCw",
    city: "Igarape",
  },
  {
    username: "patosdeminas",
    password: "BPujVXBbqz",
    city: "Patos de Minas",
  },
  {
    username: "capaobonito",
    password: "xa52UtCkY8",
    city: "Capao Bonito",
  },
  {
    username: "conselheirolafaiete",
    password: "RBAsVKkX97",
    city: "Conselheiro Lafaiete",
  },
  {
    username: "joaomonlevade",
    password: "twtmu5SVxU",
    city: "Joao Monlevade",
  },
  {
    username: "passos",
    password: "TsJI8FIQxW",
    city: "Passos",
  },
  {
    username: "salvador",
    password: "M6ipZFhmgL",
    city: "Salvador",
  },
  {
    username: "sorocaba",
    password: "xUF7J71Ddg",
    city: "Sorocaba",
  },
  {
    username: "vespasiano",
    password: "JIoP0HDvje",
    city: "Vespasiano",
  },
  {
    username: "itarare",
    password: "1p35uBXjAu",
    city: "Itarare",
  },
  {
    username: "itaperuna",
    password: "xdDS0Vm08c",
    city: "Itaperuna",
  },
  {
    username: "ouropreto",
    password: "NkogSgvngS",
    city: "Ouro Preto",
  },
  {
    username: "ribeiraodasneves",
    password: "tNGy41tu9j",
    city: "Ribeirao das Neves",
  },
  {
    username: "divinopolis",
    password: "b3nyBGtjwE",
    city: "Divinópolis",
  },
];

export const city = [
  {
    label: "Belo Horizonte - APP",
    value: "Belo Horizonte",
  },
  {
    label: "Belo Horizonte - PFDV",
    value: "Belo Horizonte PFDV",
  },
  {
    label: "Capão Bonito - SP",
    value: "Capao Bonito",
  },
  {
    label: "Conselheiro Lafaiete - MG",
    value: "Conselheiro Lafaiete",
  },
  {
    label: "Divinópolis - MG",
    value: "Divinopolis",
  },
  {
    label: "Igarapé - MG",
    value: "Igarape",
  },
  {
    label: "Itaperuna - RJ",
    value: "Itaperuna",
  },
  {
    label: "Itararé - SP",
    value: "Itarare",
  },
  {
    label: "João Monlevade - MG",
    value: "Joao Monlevade",
  },
  {
    label: "Passos - MG",
    value: "Passos",
  },
  {
    label: "Patos de Minas - MG",
    value: "Patos de Minas",
  },
  {
    label: "Ribeirão das Neves - MG",
    value: "Ribeirao das Neves",
  },
  {
    label: "Salvador - BA",
    value: "Salvador",
  },
  {
    label: "Sorocaba - SP",
    value: "Sorocaba",
  },
  {
    label: "Vespasiano - MG",
    value: "Vespasiano",
  },
  {
    label: "Ouro Preto - MG",
    value: "Ouro Preto",
  },
];

export const cityFiscals = [
  {
    label: "Capão Bonito - SP",
    value: "Capao Bonito",
  },
  {
    label: "Conselheiro Lafaiete - MG",
    value: "Conselheiro Lafaiete",
  },
  {
    label: "Divinópolis - MG",
    value: "Divinopolis",
  },
  {
    label: "Igarapé - MG",
    value: "Igarape",
  },
  {
    label: "Itaperuna - RJ",
    value: "Itaperuna",
  },
  {
    label: "Itararé - MG",
    value: "Itarare",
  },
  {
    label: "João Monlevade - MG",
    value: "Joao Monlevade",
  },
  {
    label: "Passos - MG",
    value: "Passos",
  },
  {
    label: "Patos de Minas - MG",
    value: "Patos de Minas",
  },
  {
    label: "Salvador - BA",
    value: "Salvador",
  },
  {
    label: "Sorocaba - SP",
    value: "Sorocaba",
  },
  {
    label: "Vespasiano - MG",
    value: "Vespasiano",
  },
  {
    label: "Ouro Preto - MG",
    value: "Ouro Preto",
  },
];

export const statusOrder = [
  {
    label: "Aguardando Pgto.",
    value: "WAITING",
    icon: "warning",
  },
  {
    label: "Pago",
    value: "PAID",
    icon: "success",
  },
  {
    label: "Recusado",
    value: "REFUSED",
    icon: "danger",
  },
  {
    label: "Cancelado",
    value: "CANCELED",
    icon: "secondary",
  },
  {
    label: "Desfeito",
    value: "UNDONE",
    icon: "dark",
  },
];

export const statusActivation = [
  {
    label: "Válida",
    value: "VALID",
    icon: "success",
  },
  {
    label: "Desistida",
    value: "GIVEDUP",
    icon: "warning",
  },
  {
    label: "Desfeita",
    value: "UNDONE",
    icon: "danger",
  },
];

export const statusFiscalSupervise = [
  {
    label: "Regular",
    value: "VALID",
    icon: "success",
  },
  {
    label: "Irregular",
    value: "UNDONE",
    icon: "danger",
  },
];

export const reasonFiscalSupervise = [
  {
    label: "Placa não cadastrada no sistema",
    value: "NOT_FOUND",
    icon: "danger",
  },
  {
    label: "Veículo em tolerância",
    value: "TOLERANCE",
    icon: "danger",
  },
  {
    label: "Veículo sem crédito ativo",
    value: "NO_TICKET",
    icon: "danger",
  },
  {
    label: "Veículo com crédito vencido",
    value: "EXPIRED",
    icon: "danger",
  },
  {
    label: "Tipo de veículo diferente",
    value: "WRONG_TYPE",
    icon: "danger",
  },
  {
    label: "Veículo oficial",
    value: "OFICIAL",
    icon: "dark",
  },
  {
    label: " ",
    value: null,
    icon: "",
  },
  {
    label: " ",
    value: "",
    icon: "",
  },
];

export const regularParkFiscalSupervise = [
  {
    label: "Regular",
    value: true,
    icon: "success",
  },
  {
    label: "Irregular",
    value: false,
    icon: "danger",
  },
  {
    label: "Ativ. Auto",
    value: "auto",
    icon: "dark",
  },
];

export const parkingTime = [
  {
    label: "30 minutos",
    value: 30,
  },
  {
    label: "1 hora",
    value: 60,
  },
  {
    label: "1h 30min",
    value: 90,
  },
  {
    label: "2 horas",
    value: 120,
  },
  {
    label: "2,5 horas",
    value: 150,
  },
  {
    label: "3 horas",
    value: 180,
  },
  {
    label: "4 horas",
    value: 240,
  },
  {
    label: "5 horas",
    value: 300,
  },
  {
    label: "12 horas",
    value: 720,
  },
];

export const originActivations = [
  {
    label: "PDV/PDVA",
    value: "PDV",
  },
  {
    label: "APLICATIVO",
    value: "APP",
  },
  {
    label: "WEB",
    value: "WEB",
  },
  {
    label: "ATIV. AUTO.",
    value: "MON",
  },
  {
    label: "WHATSAPP",
    value: "WPP",
  },
];

export const origin = [
  {
    label: "APP",
    value: "APP",
  },
  {
    label: "WEB",
    value: "WEB",
  },
  {
    label: "PDV/PDVA",
    value: "PDV",
  },
];

export const statusChargeback = [
  {
    label: "IN_ANALYSIS",
    value: "Em análise",
  },
  {
    label: "AUTHORIZED",
    value: "Autorizado",
  },
  {
    label: "REFUSED",
    value: "Recusado",
  },
];

export const vehicleTypes = [
  {
    label: "Carro",
    value: 1,
    icon: "car",
  },
  {
    label: "Moto",
    value: 2,
    icon: "motorcycle",
  },
  {
    label: "Caminhão",
    value: 3,
    icon: "truck",
  },
  {
    label: "Motofrete C/D",
    value: 4,
    icon: "bicycle",
  },
  {
    label: "Carga e descarga",
    value: 5,
    icon: "truck",
  },
];

export const saleValueOperator = [
  {
    label: "Maior ou Igual",
    value: ">=",
  },
  {
    label: "Menor ou Igual",
    value: "<=",
  },
  {
    label: "Igual",
    value: "=",
  },
  {
    label: "Diferente de",
    value: "!=",
  },
];

export const paymentOptions = [
  {
    label: "Cartão",
    value: "card",
  },
  {
    label: "Boleto",
    value: "billet",
  },
  {
    label: "Dinheiro",
    value: "money",
  },
  {
    label: "Voucher",
    value: "voucher",
  },
];

export const approvalFiscalSupervise = [
  {
    label: "Aprovado",
    value: "APPROVED",
    icon: "success",
  },
  {
    label: "Pendente",
    value: "PENDING",
    icon: "warning",
  },
  {
    label: "Rejeitado",
    value: "REFUSED",
    icon: "danger",
  },
];
